
let currentViewKey = null;
let currentSearch = window.location.search;

const getCurrentViewKey = () => {
    let viewKey = null;
    try {
        viewKey = window.location.pathname?.substring(1) ?? null;
    }
    catch (error) {
        viewKey = null;
        console.log(error)
    }

    return viewKey;
}

currentViewKey = getCurrentViewKey();

const listeners = {
    list: [],
    emitChange() {
        for (let listener of this.list) {
            listener();
        }
    }
};

export const currentViewHelper = {
    setView(val) {
        currentViewKey = val;
        currentSearch = "";
        window.history.pushState({}, '', val ? `${val}` : '');
        listeners.emitChange();
    },
    setQuery(key, value) {
        if ('URLSearchParams' in window) {
            const url = new URL(window.location)
            url.searchParams.set(key, value)
            currentSearch = url.search;
            window.history.pushState({}, '', url);
        }
        listeners.emitChange();
    },
    getQuery(key) {
        if ('URLSearchParams' in window) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(key);
        }
    },
    subscribe(listener) {
        listeners.list = [...listeners.list, listener];
        return () => {
            listeners.list = listeners.list.filter(l => l !== listener);
        };
    },
    getSnapshot() {
        return currentViewKey?.toLowerCase?.();
    },
    getSnapshotWithSearch() {
        return currentSearch;
    },
};

window.addEventListener("popstate", () => {
    const newViewKey = getCurrentViewKey();

    if (newViewKey !== currentViewKey) {
        currentViewKey = newViewKey;
        listeners.emitChange();
    }
    else if (currentSearch !== window.location.search) {
        currentSearch = window.location.search;
        listeners.emitChange();
    }
});