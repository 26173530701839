import React, { useState } from 'react';

import Camera, { IMAGE_TYPES, FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { httpPost } from '../utils/apiHook';

export const PhotoContent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [camError, setCamError] = useState();
    const [camFacingMode, setFacingMode] = useState(FACING_MODES.ENVIRONMENT);

    return (
        <div className="photo-content">
            {
                (
                    isLoading &&
                    <div className="center">
                        <h3>
                            Kamera wird gestartet.
                            <br />
                            Bitte die Berechtigung für die Kamera freigeben.
                        </h3>
                    </div>
                )
            }
            {
                (
                    !!camError &&
                    <div className="center">
                        <h3>
                            Kamera kann nicht verwendet werden.
                            <br/>
                            Bitte die Berechtigung für die Kamera freigeben.
                        </h3>
                        "{camError.message}"
                        <h3
                            onClick={() => { window.location.reload(); }}
                        >
                            Bitte neu laden!
                        </h3>
                    </div>
                )
                ||
                <>
                    {
                        !isLoading &&
                        <div
                            style={{
                                position: 'absolute',
                                height: '75px',
                                width: '75px',
                                left: 'calc(50% - 37.5px)',
                                top: '10px',
                                borderRadius: '50%',
                                background: 'hsla(0,0%,100%,.4)',
                                zIndex:1
                            }}
                            onClick={() => {
                                if (camFacingMode === FACING_MODES.ENVIRONMENT) {
                                    setFacingMode(FACING_MODES.USER);
                                }
                                else {
                                    setFacingMode(FACING_MODES.ENVIRONMENT);
                                }
                            }}
                        >
                                <div
                                    style={{
                                        position: 'absolute',
                                        height: '44px',
                                        width: '44px',
                                        left: 'calc((75px / 2) - (44px / 2))',
                                        top: 'calc((75px / 2) - (44px / 2))',
                                        borderRadius: '50%',
                                        background: '#fff',
                                        zIndex:2,
                                        backgroundImage: 'url("./change-camera-basic-svgrepo-com.svg")',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}
                                />
                        </div>
                    }
                    <Camera
                        onCameraStart={() => {
                            setIsLoading(false)
                        }}
                        onTakePhoto={(dataUri) => {
                            httpPost(null, '/api/photos', dataUri)
                                .catch(err => {
                                    console.error(err)
                                });
                        }}
                        onCameraError={(error) => {
                            setCamError(error);
                        }}
                        idealFacingMode={camFacingMode}
                        imageType={IMAGE_TYPES.PNG}
                        imageCompression={0.97}
                        isMaxResolution={true}
                        isSilentMode={true}
                    />
                </>
            }
        </div>
    );
}