import React, { useState } from 'react';
import { httpPost } from '../utils/apiHook';
import { currentViewHelper } from '../stores/pageViews';

const possibleAnswers = [
    "Hilker",
    "Hellebusch",
    "Hilker-Hellebusch",
    "Hellebusch-Hilker",
    "Alles bleibt wie es ist!",
    "Nur Jella nimmt Hilker-Hellebusch",
    "Nur Jonathan nimmt Hellebusch-Hilker"
]

export const GuessLastName = () => {
    const [guessChoise, setGuessChoise] = useState(null);
    const [hasGuessed, setHasGuessed] = useState(false);
    const [isError, setIsError] = useState(false);
    const [name, setName] = useState("");

    const sendResponse = async () => {

        setIsError(false);

        const res = await httpPost(null, '/api/lastname', { guessChoise, name }).catch(err => err);

        if (res.status === true) {
            setHasGuessed(true)
            setTimeout(() => { currentViewHelper.setView("/") }, 5000);
        }
        else {
            setIsError(true)
        }
    }

    return (
        (
            hasGuessed &&
            <>
                <div>
                    <div className="text-content center big">
                        Vielen Dank für deinen Tipp!
                        <br />
                        <br />
                        Wir freuen uns dich mit dem Ergebnis auf unserer Hochzeit zu Überraschen 😜
                    </div>
                </div>
                <a href="/">Zur Hauptseite</a>
            </>
        )
        ||
        <>
            <form
                style={{ width: '100%' }}
                className="space-bottom"
            >
                <label>Wer ist hier am Vermuten:</label>
                <input
                    placeholder="Vorname / Nachname"
                    value={name}
                    onChange={({ target: { value } }) => {
                        setName(value)
                    }}
                />
            </form>
            <form
                style={{ width: '100%'}}
                className="space-bottom"
            >
                <legend>Welchen Nachnamen werden wir annehmen ?</legend>
                {
                    possibleAnswers.map(answ => (
                        <div
                            key={`choise_${answ}`}
                        >
                            <input
                                type="radio"
                                id={`choise_${answ}`}
                                value={answ}
                                name="guess_lastname"
                                onChange={({ target: { value } }) => { setGuessChoise(value) }}
                            />
                            <label
                                for={`choise_${answ}`}
                            >
                                {answ}
                            </label>
                        </div>
                    ))
                }
            </form>
            {
                isError &&
                <p className="center space-bottom" style={{ background: "rgba(255,0,0,.1)", width: '100%'}}>
                        {"Fehler bei der Verareitung, bitte später nochmal versuchen."}
                </p>
            }
            <button
                onClick={() => sendResponse()}
                style={{ width: '50%' }}
                className="space-bottom"
                disabled={!guessChoise || !name}
            >
                Absenden
            </button>
        </>
    );
}