import React, { useEffect, useState } from 'react';
import { addEventListener, removeEventListener, sendMessage, startWebSocketConnection, stopWebSocketConnection } from '../utils/hubs';

export const QuizMaster = () => {

    const [pass, setPass] = useState("");
    const [status, setStatus] = useState(0);
    const [playerCount, setPlayerCount] = useState(0);
    const [question, setQuestion] = useState(null);
    const [endcard, setEndcard] = useState(null);
    const [showEndgame, setShowEndgame] = useState(false);

    useEffect(() => {
        startWebSocketConnection("/api/quizmaster");
        addEventListener(
            "UpdatePlayerCount",
            (c) => {
                setPlayerCount(c);
            }
        );
        addEventListener(
            "Status",
            (s) => {
                setStatus(s);
            }
        );
        addEventListener(
            "UpdateQuestion",
            (q, isLast) => {
                setQuestion(q);
                setShowEndgame(isLast)
            }
        );
        addEventListener(
            "Endcard",
            (e) => {
                setEndcard(e);
            }
        );

        return () => {
            removeEventListener(
                "UpdatePlayerCount"
            );
            removeEventListener(
                "Status"
            );
            removeEventListener(
                "UpdateQuestion",
            );
            removeEventListener(
                "Endcard",
            );
            stopWebSocketConnection();
        }
    }, [])

    return (
        (
            pass !== "9318" &&
            <>
                <input
                    placeholder="Passwort"
                    value={pass}
                    type="password"
                    onChange={({ target: { value } }) => {
                        setPass(value)
                    }}
                />
            </>
        )
        ||
        <>
            <div style={{marginTop: '1em'}}>
                <h1>Das Hochzeits-Quiz</h1>
            </div>
            <div>
                <div>
                    <h2>Anzahl Spieler: </h2>
                </div>
                <div>
                    <h2>{playerCount}</h2>
                </div>
            </div>
            <div>
                {
                    (
                        status === 0 &&
                        <div>
                            <button
                                className="center"
                                style={{ width: "100%" }}
                                onClick={() => {
                                    sendMessage("StartGame")
                                }}
                            >
                                Spiel starten
                            </button>
                        </div>
                    )
                    ||
                    (
                        status === 1 &&
                        <>
                            <div>
                                <button
                                    className="center"
                                    style={{ width: "calc(100% - .7em)", margin: "0 .35em" }}
                                    onClick={() => {
                                        sendMessage("PrevQuestion")
                                    }}
                                >
                                    Vorherige Frage
                                </button>
                            </div>
                            <div>
                                <button
                                    className="center"
                                    style={{ width: "calc(100% - .7em)", margin: "0 .35em" }}
                                    onClick={() => {
                                        if (showEndgame) {
                                            sendMessage("EndGame")
                                        }
                                        else {
                                            sendMessage("NextQuestion")
                                        }
                                    }}
                                >
                                    {showEndgame ? "Quiz abschließen" : "Nächste Frage"}
                                </button>
                            </div>
                        </>
                    )
                    ||
                    (
                        status === 2 &&
                        <div>
                            <button
                                className="center"
                                style={{ width: "100%" }}
                                onClick={() => {
                                    sendMessage("ResetGame")
                                }}
                            >
                                Spiel zurücksetzen
                            </button>
                        </div>
                    )
                }
            </div>
            {
                (
                    (status === 1 && !!question) &&
                    <>
                        <div>
                            <p className="center" style={{ marginBottom: '1em' }}>
                                Antworten: {question.answerCount}
                            </p>
                        </div>
                        <div>
                            <h2 style={{ marginBottom: '1em' }}>
                                {question.title}
                            </h2>
                        </div>
                        {
                            Object.keys(question.answerList)
                                .map((key, i) => (
                                    <div key={`k_${i}`}>
                                        <h2 style={{ marginBottom: '1em' }}>{key}</h2>
                                        <p
                                           
                                            style={{
                                                width: '100%',
                                                backgroundImage: `linear-gradient(to right, #3FFFA2 0%, #3FFFA2 ${question.answerList[key]}%, #DCA1A155 ${question.answerList[key]}%, #DCA1A155 100%)`
                                            }}
                                            className="space-bottom center"
                                        >
                                            {question.answerList[key]} %
                                        </p>
                                    </div>
                                ))
                        }
                    </>
                )
            }
            {
                (
                    (status === 2 && !!endcard) &&
                    endcard.map((entety, eindex) => (
                        <div key={`k_${eindex}_group`} className="finalCardRow">
                            <div>
                                <h2 style={{ marginBottom: '.5em' }}>
                                    {entety.title}
                                </h2>
                                <p className="center" style={{ width: '100%' }}>
                                    Antworten: {entety.answerCount}
                                </p>
                            </div>
                            {
                                Object.keys(entety.answerList)
                                    .map((key, i) => (
                                        <div key={`k_${eindex}_${i}`}>
                                            <h2 style={{...(entety.correctIndex === i ? { background: "#3FFFA2" } : {}) }}>{key}</h2>
                                            <p
                                           
                                                style={{
                                                    width: '75%',
                                                    backgroundImage: `linear-gradient(to right, #3FFFA2 0%, #3FFFA2 ${entety.answerList[key]}%, #DCA1A155 ${entety.answerList[key]}%, #DCA1A155 100%)`
                                                }}
                                                className="space-bottom center"
                                            >
                                                {entety.answerList[key]} %
                                            </p>
                                        </div>
                                    ))
                            }
                        </div>
                    ))
                )
            }
        </>
    );
}