import React from 'react';
import { CountDown } from "./CountDown";


export const PlanPartOne = () => {
    return (
        <>
            <CountDown finalDate={new Date('2025-07-04T11:00:00')} />
            <div>
                <div>
                    <h2>Trauungsort</h2>
                </div>
                <div className="right-background left-padded with-link">
                    Lohgerberei
                    <br />
                    Große Straße 3a
                    <br />
                    49439 Steinfeld
                    <br />
                    Parkplätze hinter der Volksbank.
                    <a
                        href="https://maps.app.goo.gl/sZFcLb2jfiDjuVmB6"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Wohin genau?
                    </a>
                </div>
            </div>
            <div>
                <div>
                    <h2>Der Plan (Teil 1)</h2>
                </div>
                <div className="right-background left-padded">
                    11:00 Uhr - <b>Trauung (für ausgewählte Personen, da Platzmangel)</b>
                    <br />
                    11:30 Uhr - <b>Sektempfang vor der Lohgerberei</b>
                    <br />
                    12:00 Uhr - <b>Fotos machen bei der Lohgerberei</b>
                    <br />
                    13:30 Uhr - <b>Fahrt zur "Alte Hofburg"</b>
                    <br />
                    14:00 Uhr - <b>Kaffee und Kuchen (für ausgewählte Personen, da Geldmangel 😉)</b>
                    <br />
                    18:00 Uhr - <b>"Ende" Teil 1</b>
                    <br />
                    18:01 Uhr - <b>offen für alle in der "Alte Hofburg"</b>
                </div>
            </div>
            <div>
                <div>
                    <h2>Kaffe-Kuchen-Adresse</h2>
                </div>
                <div className="right-background left-padded with-link">
                    Alte Hofburg
                    <br />
                    Große Straße 21
                    <br />
                    49401 Damme
                    <br />
                    Parkplatz bei Combi Verbrauchermarkt 
                    oder gegenüber von Avanti Pizza Damme.
                    <a
                        href="https://maps.app.goo.gl/eCwuDLV4ry1kqD1G8"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Wohin genau?
                    </a>
                </div>
            </div>
        </>
    )
}