import React, { useState } from "react";
import { httpPost } from "../utils/apiHook";

export const FaqComp = () => {

    const [question, setQuestion] = useState("");
    const sendQuestion = async () => {

        const res = await httpPost(null, '/api/faq', question).catch(err => err);

        if (res.status === true) {
            setQuestion("");
        }
        else {
            alert("Fehler bei der Verareitung, bitte später nochmal versuchen.")
        }
    }
    return (
        <>
            <div>
                <h2>FAQ</h2>
            </div>
            <div>
                <div>
                    <h2>Was mache ich mit meinem Kind?</h2>
                </div>
                <div className="right-background left-padded">
                    Beim Standesamt am 04. Juli ist vor Ort ein Spielplatz, fall das Kind lieber draußen spielen möchte.
                    <br />
                    Auf dem Saal sind die Kleinen herzlich willkommen.
                    <br />
                    Bitte denkt dran, dass diese hochfeierliche Tanzveranstaltung durchaus bis in die frühen Morgenstunden gehen wird.
                    <br />
                    Gerne zu beiden Tagen Spielzeug mitbringen.
                </div>
            </div>
            <div>
                <div>
                    <h2>Was mache ich mit meinem Haustier?</h2>
                </div>
                <div className="right-background left-padded">
                    Im Standesamt am 04.Juli sind keine Tiere erlaubt.
                    <br />
                    Im Anschluss bei der alten Hofburg sind Tiere erlaubt.
                    <br />
                    Bitte am 05.Juli keine Haustiere zum Saal mitbringen!
                </div>
            </div>
            <div>
                <div>
                    <h2>Darf ich zur Standesamtlichen Hochzeit kommen?</h2>
                </div>
                <div className="right-background left-padded">
                    Ja, gerne kannst du zur Standesamtlichen Hochzeit kommen.
                    <br />
                    Aus platztechnischen Gründen können leider nicht alle mit reinkommen.
                    <br />
                    Nach dem Standesamt werden wir noch Fotos mit allen vor Ort machen.
                </div>
            </div>
            <div>
                <div>
                    <h2>Kleiderordnung auf dem Saal?</h2>
                </div>
                <div className="right-background left-padded">
                    Schicke Abendkleider und Anzüge
                    <br />
                    Tanzschuhe und Wechselschuhe
                </div>
            </div>
            <div>
                <div>
                    <h2>Wen habt ihr als DJ?</h2>
                </div>
                <div className="right-background left-padded with-link">
                    DJ Mr. Rocket (Torsten Korte)
                    <a
                        href="https://www.facebook.com/dj.mr.rocket/?locale=de_DE"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Wer genau?
                    </a>
                </div>
            </div>
            <div>
                <div>
                    <h2>Wen habt ihr als Fotograph?</h2>
                </div>
                <div className="right-background left-padded with-link">
                    Norbert von der Heide
                    <a
                        href="https://www.dasoertliche.de/Themen/Heide-Norbert-von-der-Damme-Borringhausen-Auf-den-Kuhlen"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Wer genau?
                    </a>
                </div>
            </div>
            <div>
                <div>
                    <h2>Was wünscht ihr euch?</h2>
                </div>
                <div className="right-background left-padded">
                    Zwei schöne, feierliche Tage.
                    <br />
                    Am aller liebsten nehmen wir finanzielle unterstützung für Haus und Kinder entgegen.
                    <br />
                    Wenn ihr lieber was persönliches schenken wollt, könnt ihr uns gerne mit etwas selbst Gemachtem überraschen 😉.
                    <br />
                    Für alle Kartengeschenke wird eine Box parat stehen.
                </div>
            </div>
            <div>
                <h2>Habt ihr noch fragen?</h2>
            </div>
            <div>
                <input
                    placeholder="Frage ..."
                    value={question}
                    onChange={({ target: { value } }) => {
                        setQuestion(value)
                    }}
                />
            </div>
            <div className="space-bottom">
                <button
                    disabled={question.length <= 0}
                    onClick={() => {
                        sendQuestion()
                    }}
                >
                    Abschicken
                </button>
            </div>
        </>
    );
}