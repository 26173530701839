import React, { useEffect, useState } from "react"
import { httpPost } from "../utils/apiHook.js";
import { currentViewHelper } from "../stores/pageViews.js";

const defaultItem = {
    id: 0,
    firstname: "",
    lastname: "",
    isAdult: true,
    isOnTheList: false
}


export const WeddingResponse = () => {

    const [errorText, setErrorText] = useState(null);
    const [emailAddress, setEmailAddress] = useState("");
    const [personList, setPersonList] = useState([{ ...defaultItem }]);
    const [isRegistrated, setIsRegistrated] = useState(false);

    const sendResponse = async () => {
        const eList = [];

        if (emailAddress.length <= 0) {
            eList.push("Email-Adresse wird benötigt.");
        }

        personList
            .forEach((x,i) => {
                if (x.firstname.length <= 0) {
                    eList.push(`Bei Person ${i + 1} ist der Vorname nicht gesetzt.`)
                }
                if (x.lastname.length <= 0) {
                    eList.push(`Bei Person ${i + 1} ist der Nachname nicht gesetzt.`)
                }
            })


        if (
            eList.length <= 0
        ) {
            setErrorText(null)

            const res = await httpPost(null, '/api/responses', { emailAddress, personList }).catch(err => err);

            if (res.status === true) {
                setIsRegistrated(true)
                setTimeout(() => { currentViewHelper.setView("nachname") }, 5000);
            }
            else {
                setErrorText(["Fehler bei der Verareitung, bitte später nochmal versuchen."])
            }
        }
        else {
            setErrorText(eList)
        }
    }

    useEffect(() => {
        if (personList.length <= 0) {
            setPersonList([{ ...defaultItem }])
        }
    }, [personList])

    return (
        <>
            <div>
                <h2>Anmeldung</h2>
            </div>
            {
                (
                    isRegistrated &&
                    <>
                        <div>
                            <div className="text-content center big">
                                Vielen Dank für deine Rückmeldung!
                                <br />
                                <br />
                                Wir freuen uns auf dich{personList.length > 1 ? " und deine Begeitpersonen" : ""}!
                            </div>
                        </div>
                        <a className="space-bottom" href="/nachname">Ich möchte den Nachnamen raten</a>
                        <a href="/">Zur Hauptseite</a>
                    </>
                )
                ||
                <>
                    <form
                        style={{ width: '100%' }}
                        className="space-bottom"
                    >
                        <label>E-Mail</label>
                        <input
                            type="email"
                            value={emailAddress}
                            onChange={({ target: { value } }) => {
                                setEmailAddress(value)
                            }}
                        />
                    </form>
                    {
                        personList
                            .map((item, index) => (
                                <form
                                    className="space-bottom"
                                    style={{ width: '100%' }}
                                    key={`response_list_${item.id}`}
                                >
                                    <div className="space-bottom" style={{ display: 'flex' }}>
                                        <h2 className="center">
                                            Person {index + 1}
                                        </h2>
                                        <button
                                            style={{ background: 'rgba(255,0,0,.1)' }}
                                            onClick={() => {
                                                setPersonList([...personList.filter(x => x.id !== item.id)])
                                            }}
                                        >
                                            Entfernen
                                        </button>
                                    </div>
                                    <label>Vorname</label>
                                    <input
                                        value={item.firstname}
                                        onChange={({ target: { value } }) => {
                                            setPersonList([...personList.map(x => x.id === item.id ?
                                                {
                                                    ...x,
                                                    firstname: value
                                                }
                                                :
                                                x
                                            )])
                                        }}
                                    />
                                    <label>Nachname</label>
                                    <input
                                        value={item.lastname}
                                        onChange={({ target: { value } }) => {
                                            setPersonList([...personList.map(x => x.id === item.id ?
                                                {
                                                    ...x,
                                                    lastname: value
                                                }
                                                :
                                                x
                                            )])
                                        }}
                                    />
                                    <label>Ist Erwachsen</label>
                                    <input
                                        type="checkbox"
                                        checked={item.isAdult}
                                        onChange={({ target: { checked } }) => {
                                            setPersonList([...personList.map(x => x.id === item.id ?
                                                {
                                                    ...x,
                                                    isAdult: checked
                                                }
                                                :
                                                x
                                            )])
                                        }}
                                    />
                                    <label>"Ich komme"</label>
                                    <input
                                        type="checkbox"
                                        checked={item.isOnTheList}
                                        onChange={({ target: { checked } }) => {
                                            setPersonList([...personList.map(x => x.id === item.id ?
                                                {
                                                    ...x,
                                                    isOnTheList: checked
                                                }
                                                :
                                                x
                                            )])
                                        }}
                                    />
                                </form>
                            ))
                    }
                    <button
                        onClick={() => {
                            const p = {
                                ...defaultItem,
                                id: Math.min(...personList.map(x => x.id)) - 1
                            }
                            setPersonList([
                                ...personList,
                                p
                            ])
                        }}
                        style={{ width: '50%' }}
                        className="space-bottom"
                    >
                        weitere Person
                    </button>
                    {
                        !!errorText &&
                        errorText.map((x,i) => (
                            <p key={`error_index_${i}`} className="center space-bottom" style={{background: "rgba(255,0,0,.1)", width: '100%'}}>
                                {x}
                            </p>
                        ))  
                    }
                    <button
                        onClick={() => sendResponse()}
                        style={{width: '50%'}}
                        className="space-bottom"
                    >
                        Absenden
                    </button>
                </>
            }
        </>
    )
}