import { FaqComp } from "./FaqComp"


export const PlaningExtras = () => {

    return (
        <>
            <div className="space-bottom">
                <div>
                    <h2>Unterkunft</h2>
                </div>
                <div>
                    <div className="right-background left-padded with-link space-bottom" style={{ width: 'calc(100% - 1em)' }}>
                        Waldhotel zum Bergsee OHG
                        <br />
                        Wellenweg 6
                        <br />
                        49401 Damme
                        <a
                            href="https://maps.app.goo.gl/3hYgc42hu67Y4cXJ7"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Wohin genau?
                        </a>
                    </div>
                    <div className="right-background left-padded with-link space-bottom" style={{width: 'calc(100% - 1em)'}}>
                        Bergmark Hotel
                        <br />
                        Zur Schemder Bergmark 20
                        <br />
                        49439 Steinfeld
                        <a
                            href="https://maps.app.goo.gl/aDBb7Em9m94w5zQB7"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Wohin genau?
                        </a>
                    </div>
                    <div className="right-background left-padded with-link" style={{ width: 'calc(100% - 1em)' }}>
                        Lindenhof Hotel Tepe
                        <br />
                        Osterdammer Str. 51
                        <br />
                        49401 Damme
                        <a
                            href="https://maps.app.goo.gl/xQfMiqJdmff82uRU9"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Wohin genau?
                        </a>
                    </div>
                </div>
            </div>
            <div>
                <h2>Tischplanung</h2>
            </div>
            <div className="space-bottom">
                <div className="right-background left-padded">
                    Folgt nach dem Anmeldeschluss (04.04.2025), bitte bis dahin eine Rückmeldung abgeben!
                </div>
            </div>
            <div>
                <h2>Speisekarte</h2>
            </div>
            <div className="space-bottom">
                <div className="right-background left-padded">
                    Folgt nach dem Anmeldeschluss (04.04.2025). (Auf Glutenfrei wird geachtet)
                </div>
            </div>
            <div>
                <h2>Getränkekarte</h2>
            </div>
            <div className="space-bottom">
                <div className="right-background left-padded">
                    Folgt nach dem Anmeldeschluss (04.04.2025). (Berentzen Traditionskorn wird auf jeden Fall serviert)
                </div>
            </div>
            <FaqComp />
        </>
    )
}