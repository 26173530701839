import React, { useSyncExternalStore } from 'react';

import './App.css';
import { PhotoContent } from './components/PhotoContent';
import { Welcome } from './components/Welcome';
import { WeddingResponse } from './components/WeddingResponse';
import { currentViewHelper } from './stores/pageViews';
import { PersonalTexts } from './components/PersonalTexts';
import { PlanPartOne } from './components/PlanPartOne';
import { PlanPartTwo } from './components/PlanPartTwo';
import { PlaningExtras } from './components/PlaningExtras';
import { GuessLastName } from './components/GuessLastName';
import { QuizMaster } from './components/QuizMaster';
import { QuizGuest } from './components/QuizGuest';

export const App = () => {

    const currentView = useSyncExternalStore(currentViewHelper.subscribe, currentViewHelper.getSnapshot);

    return (
        <div className="gridRoot">
            {
                (
                    currentView === 'anmeldung' &&
                    <>
                        <Welcome />
                        <WeddingResponse />
                    </>
                )
                ||
                (
                    currentView === 'nachname' &&
                    <>
                        <Welcome />
                        <GuessLastName />
                    </>
                )
                ||
                (
                    currentView === 'fotos' &&
                    <PhotoContent />
                )
                ||
                (
                    currentView === 'quiz' &&
                    <QuizGuest />
                )
                ||
                (
                    currentView === 'quizadmin' &&
                    <QuizMaster />
                )
                ||
                <>
                    <Welcome />
                    <PersonalTexts />
                    <hr></hr>
                    <PlanPartOne />
                    <hr></hr>
                    <PlanPartTwo />
                    <hr></hr>
                    <PlaningExtras />
                </>
            }
        </div>
    );
}
