import { HubConnectionBuilder, HttpTransportType, LogLevel, HubConnectionState } from '@microsoft/signalr';

let _connection = null;

const initHubConnection = (connStr) => new Promise(async (res) => {
    _connection = new HubConnectionBuilder()
        .withUrl(
            connStr,
            {
                transport: HttpTransportType.WebSockets,
                skipNegotiation: true
            }
        )
        .configureLogging(LogLevel.Debug)
        .build();

    _connection
        .onclose(async (err) => {
            if (err) {
                console.log(err);
                await new Promise(x => setTimeout(x, 2000));
                await startWebSocketConnection(connStr);
            }
        });

    res();
});

export const isConnected = () => _connection.state === HubConnectionState.Connected;

export const addEventListener = (...params) => { _connection?.on(...params) };

export const removeEventListener = (...params) => { _connection?.off(...params) };

export const sendMessage = (...params) => { _connection?.invoke(...params) };

export const startWebSocketConnection = (connStr) => new Promise(async (res) => {

    if (!_connection) {
        await initHubConnection(connStr);
    }

    if (_connection.state === HubConnectionState.Disconnected) {
        _connection
            .start()
            .then(() => { res() })
            .catch(err => {
                console.log(err);
            });
    }
    else {
        res();
    }
});

export const stopWebSocketConnection = () => {
    if (_connection?.state === HubConnectionState.Connected) {
        _connection
            ?.onclose((err) => {
                console.log("stopping connection", err)
            });

        _connection
            ?.stop()
            ?.catch(err => {
                console.log(err);
            });

        _connection = null;
    }
};