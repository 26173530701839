import React, { useEffect, useState } from 'react';
import { addEventListener, removeEventListener, sendMessage, startWebSocketConnection, stopWebSocketConnection, isConnected } from '../utils/hubs';

const localStore_playerID_key = "jj-hochzeit-UserID";

export const QuizGuest = () => {

    const [status, setStatus] = useState(0);
    const [question, setQuestion] = useState(null);
    const [selected, setSelected] = useState(null);
    const [endcard, setEndcard] = useState({});

    useEffect(() => {
        startWebSocketConnection("/api/quiz")
            .then(async () => {
                while (isConnected() === false) {
                    await new Promise(res => setTimeout(res, 1000))
                }
                sendMessage(
                    "UpdateUserID",
                    localStorage.getItem(localStore_playerID_key)
                );
            });
        addEventListener(
            "UpdateQuestion",
            (q, s) => {
                setSelected(s);
                setQuestion(q);
            }
        )
        addEventListener(
            "Status",
            (s) => {
                setStatus(s);
            }
        );
        addEventListener(
            "UpdateUserID",
            (id) => {
                localStorage.setItem(localStore_playerID_key, id);
            }
        );
        addEventListener(
            "Endcard",
            (e) => {
                setEndcard(e);
            }
        );
        
        return () => {
            removeEventListener(
                "UpdateQuestion"
            );
            removeEventListener(
                "Status"
            );
            removeEventListener(
                "UpdateUserID",
            );
            removeEventListener(
                "Endcard",
            );
            stopWebSocketConnection();
        }
    }, []);

    return (
        <>
            <div style={{ marginTop: '1em' }}>
                <h1>Willkommen zum Quiz</h1>
            </div>
            {
                (
                    (status === 1 && !!question) &&
                    <>
                        <div>
                            <h2>
                                {question.title}
                            </h2>
                        </div>
                        {
                            question.answerList.map((x,i) => (
                                <button
                                    key={`k_${i}`}
                                    style={{ width: '100%' }}
                                    className="space-bottom"
                                    disabled={selected === i}
                                    onClick={() => {
                                        setSelected(i)
                                        sendMessage("AnswerQuestion", i)
                                    }}
                                >
                                    {x}
                                </button>
                            ))
                        }
                    </>
                )
                ||
                (
                    status === 2 &&
                    <>
                        <div>
                            <h2>
                                {"Das Quiz ist beendet."}
                                <br />
                                <br />
                                {"Wir bedanken uns für deine Beteilgung."}
                            </h2>
                        </div>
                        <div>
                            <h2>
                                {`Anzahl Fragen: ${endcard.questionCount}`}
                                <br />
                                <br />
                                {`Beantwortete Fragen: ${endcard.answeredQuestions}`}
                                <br />
                                <br />
                                {`Richtig Beantwortet: ${endcard.correctQuestions}`}
                            </h2>
                        </div>
                        {
                            Object.keys(endcard.wrongAnswers ?? {})
                                .map(key => (
                                    <div>
                                        <h2 style={{ marginBottom: '.5em' }}>
                                            {key}
                                        </h2>
                                        <p className="center" style={{ background: '#DCA1A155', marginBottom: '1em' }}>
                                            {endcard.wrongAnswers[key]}
                                        </p>
                                    </div>
                                ))
                        }
                    </>
                )
                ||
                <div>
                    <h2>
                        {"Das Quiz ist noch nicht gestartet."}
                        <br />
                        <br />
                        {"Bitte einen Moment Geduld."}
                    </h2>
                </div>
            }
        </>
    );
}