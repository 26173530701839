import React from 'react';

export const PersonalTexts = () => {
    const getAge = (dateString) => {
        let today = new Date();
        let birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    return (
        <>
            <div>
                <h2>Brautpaar</h2>
            </div>
            <div>
                <div className="text-content">
                    Moin, wir sind Jella, {getAge('1997-01-14')} Jahre jung und Jonathan, {getAge('1997-02-26')} Jahre alt.
                    Wir lieben es, in der Natur Spaziergänge zu machen. Unsere Lieblingsfarben sind blau und grün.
                </div>
            </div>
            <div className="center space-bottom">
                <img src="BrautPaar.jpg" alt="Brautpaar" />
            </div>
            <div>
                <h2>Unsere Geschichte</h2>
            </div>
            <div>
                <div className="text-content">
                    Unsere Geschichte begann im Jahr 2014, als wir uns zum ersten Mal trafen.
                    Zunächst waren wir beiden Teil der gleichen Gruppe und kannten uns als Bekannte oder als Freunde von Freunden.
                    Dann als die Corona-Zeit kam und man nur noch ausgewählte Menschen treffen durfte, begannen unsere langen und intensiven Spaziergänge durch die grünen Wälder in der Umgebung.
                    Dabei wurde uns beiden schnell klar, dass zwischen uns etwas ganz Besonderes entstand.
                    <br />
                    <br />
                    An einem warmen, schwülen Sommertag beschlossen wir erneut einen Spaziergang zu machen.
                    Auf geheimnisvolle Weise fanden wir uns plötzlich auf dem Kalkrieser Berg wieder, von dem aus wir einen atemberaubenden Sonnenuntergang erlebten.
                    Nach dem die Sonne langsam untergegangen war, wollten wir uns auf den Rückweg zum Auto begeben.
                    Dabei kamen wir an einem herrlich warmen Teich vorbei und sprangen ganz spontan hinein … der perfekte Moment für den ersten Kuss … mit zu viel Schwung.
                    <br />
                    <br />
                    Es dauerte jedoch bis zum 3. Juli 2021, bis sich unsere Beziehung nach vielen intensiven Gesprächen weiter vertiefte.
                    Die Spaziergänge durch Wälder und Felder führten uns immer näher zusammen.
                    Nach einem gemeinsamen Abend mit guten Freunden in Münster begann unsere Liebesgeschichte.
                    <br />
                    <br />
                    Seitdem hat sich die Zahl der Spaziergänge zwar verringert, doch unsere Liebe und das Vertrauen zueinander sind immer stärker geworden.
                    Die Entscheidung, gemeinsam unseren Lebensweg zu gehen, hat uns gezeigt, dass wir nach einem Jahr zusammen noch mehr miteinander teilen wollten.
                    So zogen wir am 30. Januar 2022 zusammen in die Wohnung in Steinfeld.
                    <br />
                    <br />
                    Mit vielen schönen Erinnerungen aus neun Jahren als Bekannte, Vertraute und Freunde, stellte ich Jonathan am 1. Juli 2023 eine Frage, die unseren bunten Tag noch besonderer machte – er hat tatsächlich „JA“ gesagt.
                    <br />
                    <br />
                    <i>"Unsere Liebe ist wie der Wald, durch den wir so oft spaziert sind – tief verwurzelt und immer weiter wachsend."</i>
                </div>
            </div>
            <div>
                <div className="TrauzeuginSpalte">
                    <h2>Trauzeugin</h2>
                    <div className="text-content">
                        Moin, ich bin Julia, bin 27 Jahre alt.
                        <br />
                        Ich bin die Zwillingsschwester von Jella.
                        <br />
                        <div className="center">
                            <img src="Trauzeugin.jpg" alt="Trauzeugin" />
                        </div>
                    </div>
                </div>
                <div>
                    <h2>Trauzeuge</h2>
                    <div className="text-content">
                        Moin, ich bin Max, bin 25 Jahre alt.
                        <br />
                        Ich bin der beste Freund von Jonathan.
                        <br />
                        <div className="center">
                            <img src="Trauzeuge.jpg" alt="Trauzeuge" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}