import { CountDown } from "./CountDown"


export const PlanPartTwo = () => {
    return (
        <>
            <CountDown finalDate={new Date('2025-07-05T17:00:00')} />
            <div>
                <div>
                    <h2>Party Adresse</h2>
                </div>
                <div className="right-background left-padded with-link">
                    Saalbetrieb Fangmann
                    <br />
                    Kirchstraße 21
                    <br />
                    49401 Damme
                    <br />
                    Parkplätze vorhanden.
                    <a
                        href="https://maps.app.goo.gl/GMTkoKoKWQ7jwZxP9"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Wohin genau?
                    </a>
                </div>
            </div>
            <div>
                <div>
                    <h2>Der Plan (Teil 2)</h2>
                </div>
                <div className="right-background left-padded">
                    17:00 - <b>Sektempfang beim Saal</b>
                    <br />
                    18:00 - <b>Essen am Buffet</b>
                    <br />
                    19:00 - <b>Nachtisch und Absacker</b>
                    <br />
                    20:00 - <b>"Gesoffen und Getanzen"</b>
                </div>
            </div>
        </>
    )
}